
export default {
  name: "menu-seleccion-colores",
  props: {
    model: { type: Boolean, required: true },
    color: { type: String, required: true },
    coloresOpciones: { type: Array, required: true },
  },
  data() {
    return {
      mostrar: false,
      colorSeleccionado: this.color,
    };
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
    color(val) {
      this.colorSeleccionado = val;
    },
  },
  methods: {
    seleccionarColor(color) {
      this.$refs.menuColores.hide();
      this.$emit("seleccionar-color", color);
    },
  },
};
